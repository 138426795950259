import React from 'react';
import './footer.css';

function Footer() {
  return (
    <footer className="login-footer login-footer--landscape">
      <div className="login-footer__container login-footer__container--privacy">
        <a
          className="andes-typography login-footer__mercadolibre-privacy-link andes-typography--type-body andes-typography--size-xs andes-typography--color-link andes-typography--weight-regular"
          href="https://www.mercadolivre.com.br/privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Como cuidamos da sua privacidade
        </a>
        <span className="andes-typography login-footer__mercadolibre-copyright andes-typography--type-body andes-typography--size-xs andes-typography--color-secondary andes-typography--weight-regular">
          Copyright © 1999-2024 Ebazar.com.br LTDA.
        </span>
      </div>
      <span className="andes-typography login-footer__container login-footer__container--recaptcha andes-typography--type-body andes-typography--size-xs andes-typography--color-secondary andes-typography--weight-regular">
        Protegido por reCAPTCHA
        <a
          className="andes-typography login-footer__recaptcha-link andes-typography--type-body andes-typography--size-xs andes-typography--color-primary andes-typography--weight-regular"
          href="https://policies.google.com/privacy?hl=pt-BR"
        >
          Privacidade
        </a>
        <a
          className="andes-typography login-footer__recaptcha-link andes-typography--type-body andes-typography--size-xs andes-typography--color-primary andes-typography--weight-regular"
          href="https://policies.google.com/terms?hl=pt-BR"
        >
          Condições
        </a>
      </span>
    </footer>
  );
}

export default Footer;