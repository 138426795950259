import React from 'react';
import './header.css';

function Header() {
  return (
    <header className="nav-header">
      <div className="nav-bounds">
        <a className="nav-logo" href="https://www.mercadolivre.com.br">
          <img src="../../../img/ml-wide.png" alt="Mercado Livre" className="nav-logo__image"/>
        </a>
      </div>
    </header>
  );
}

export default Header;
