import React from 'react';
import './VerificationMethods.css';

/**
 * Componente VerificationMethods
 * 
 * Este componente exibe uma lista de métodos de verificação e permite que o usuário selecione um método.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {function} props.onMethodSelect - Função chamada quando um método é selecionado. Recebe o tipo do método selecionado como argumento.
 * @param {Array} props.methods - Lista de métodos de verificação disponíveis. Cada método é um objeto com uma propriedade `type`.
 * 
 * @returns {JSX.Element} - Retorna um elemento JSX que representa a interface de seleção de métodos de verificação.
 * 
 * @example
 * const methods = [{ type: 'Email' }, { type: 'SMS' }];
 * const handleMethodSelect = (methodType) => { console.log(methodType); };
 * <VerificationMethods onMethodSelect={handleMethodSelect} methods={methods} />
 */
const VerificationMethods = ({ onMethodSelect, methods }) => {
  return (
    <div className="verification-methods-container">
      <h1>Selecione um método de verificação</h1>
      <ul>
        {methods.map((method) => (
          <li key={method.type}>
            <button onClick={() => onMethodSelect(method.type)}>{method.type}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VerificationMethods;
