import React from 'react';
import { isMobile } from 'react-device-detect';
import './DeviceWarning.css';

const DeviceWarning = () => {
  return (
    isMobile && (
      <div className="device-warning">
        <p>Por favor, acesse esta aplicação de um computador ou notebook para uma melhor experiência.</p>
      </div>
    )
  );
};

export default DeviceWarning;
