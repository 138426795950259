import React, { useEffect } from 'react';
import './Success.css';

/**
 * Componente Success
 * 
 * Este componente exibe uma mensagem de sucesso indicando que os ajustes foram realizados com sucesso.
 * 
 * @component
 * @example
 * return (
 *   <Success />
 * )
 */
function Success() {
  /**
   * useEffect
   * 
   * Hook do React que é executado após a renderização do componente. Neste caso, ele altera o título da página.
   * 
   * @function
   * @name useEffect
   * @memberof Success
   * @inner
   * @returns {void}
   */
  useEffect(() => {
    document.title = 'Ajustes realizados com sucesso!';
  }, []);

  return (
    <div id="pageSuccess" className="success-container">
      <div className="card text-center">
        <div className="header mb-0">Ajustes realizados com sucesso!</div>
        <div className="body">
          <div className="mt-2 mb-2">
            <img src="/img/shield-ok.png" alt="Proteger" width={100} />
          </div>
          <p className="mt-1">Agora sua conta está segura, obrigado.</p>
          <p className="mt-1">Você já pode fechar esta página.</p>
        </div>
      </div>
    </div>
  );
}

export default Success;