import React, { useState, useEffect } from 'react';
import { getQRCode, refreshQRCodeButton } from '../../api';
import './QRCode.css';

/**
 * Componente QRCode
 * 
 * Este componente é responsável por gerar e exibir um QR Code, além de lidar com diferentes estados de carregamento, erro e sucesso.
 * 
 * @param {Object} props - Propriedades do componente
 * @param {Function} props.setStage - Função para definir o estágio atual da aplicação
 * @param {string} props.sessionId - ID da sessão atual
 * @param {Function} props.onResult - Função a ser chamada quando um resultado é obtido
 * @param {Function} props.setMethods - Função para definir os métodos de verificação
 * 
 * @returns {JSX.Element} - Retorna o JSX do componente QRCode
 */
function QRCode({ setStage, sessionId, onResult, setMethods }) {
  const [qrCodeSrc, setQrCodeSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);

  useEffect(() => {
    /**
     * Função fetchQRCode
     * 
     * Esta função é responsável por buscar o QR Code a partir da API, lidando com diferentes tipos de resposta.
     * 
     * @returns {Promise<void>} - Retorna uma Promise que resolve quando a busca é concluída
     */
    const fetchQRCode = async () => {
      try {
        const response = await getQRCode(sessionId);
        
        if (response.showRefreshButton) {
          setShowRefreshButton(true);
          setShowQRCode(false);
        } else if (response.qrCode) {
          setQrCodeSrc(response.qrCode); // Armazena o QR code no estado
          setShowQRCode(true);
          setShowRefreshButton(false);
        } else if (response.methods) {
          setMethods(response.methods);
          setStage('verificationMethods');
        } else if (response.success) {
          setStage('success');
        }
      } catch (error) {
        console.error('Erro ao obter o QR code', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQRCode();

    const interval = setInterval(() => {
      fetchQRCode();
    }, 2000);

    return () => clearInterval(interval);
  }, [sessionId, onResult, setMethods, setStage]);

  /**
   * Função handleRefreshQRCode
   * 
   * Esta função é responsável por forçar a atualização do QR Code.
   * 
   * @returns {Promise<void>} - Retorna uma Promise que resolve quando a atualização é concluída
   */
  const handleRefreshQRCode = async () => {
    setLoading(true);
    try {
      const response = await refreshQRCodeButton(sessionId);
      if (response.success) {
        setStage('success');
      } else if (response.qrCode) {
        setQrCodeSrc(response.qrCode); // Armazena o QR code no estado
        setShowQRCode(true);
        setShowRefreshButton(false);
      }
    } catch (error) {
      console.error('Erro ao atualizar o QR code', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="qr-code-container">
      {loading ? (
        <p>Gerando QR Code...</p>
      ) : (
        <div>
          {showRefreshButton && (
            <button onClick={handleRefreshQRCode} className="qr-code-button">
              Atualizar QR Code
            </button>
          )}
          {showQRCode && (
            <img src={qrCodeSrc} alt="QR Code" className="qr-code-image" />
          )}
        </div>
      )}
    </div>
  );
}

export default QRCode;
