import React, { useState } from 'react';
import './VerificationCode.css';

/**
 * Componente VerificationCode
 * 
 * Este componente renderiza um formulário para a inserção de um código de verificação.
 * 
 * @param {Object} props - As propriedades do componente.
 * @param {function} props.setStage - Função para alterar o estágio do processo de verificação.
 * @param {string} props.selectedMethod - Método de verificação selecionado.
 * @param {string} props.sessionId - ID da sessão atual.
 * @param {function} props.onCodeValidate - Função para validar o código inserido.
 * 
 * @returns {JSX.Element} O componente de código de verificação.
 */
function VerificationCode({ setStage, selectedMethod, sessionId, onCodeValidate }) {
  // Estado local para armazenar o código inserido pelo usuário
  const [code, setCode] = useState('');
  // Estado local para armazenar mensagens de erro
  const [errorMessage, setErrorMessage] = useState('');

  /**
   * Função handleSubmit
   * 
   * Esta função é chamada quando o formulário é submetido. Ela previne o comportamento padrão do formulário,
   * valida o código inserido chamando a função onCodeValidate e atualiza o estado do componente com base no resultado.
   * 
   * @param {Event} e - O evento de submissão do formulário.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await onCodeValidate(code);
    console.log('Code validation result:', result); // Adicionado console.log para exibir o resultado completo

    if (result.success) {
      console.log('Code validation successful, setting stage to success'); // Adicionado console.log
      setStage('success');
    } else {
      console.log('Code validation failed, displaying error message'); // Adicionado console.log
      setErrorMessage(result.message || 'Invalid code');
      setCode('');
    }
  };

  return (
    <div className="verification-code-container">
      <h1>Insira o código de verificação</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          maxLength="6"
          required
        />
        <button type="submit">Verificar</button>
      </form>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
}

export default VerificationCode;
