import React, { useState, useEffect, useCallback } from "react";
import { login } from '../../api';
import "./Login.css";

function Login({ setStage, setSessionId, initialEmail }) {
  const [email, setEmail] = useState(initialEmail || ""); // Preenche com o email da URL se disponível
  const [error, setError] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // Define handleSubmit usando useCallback para evitar recriações desnecessárias
  const handleSubmit = useCallback(async (e) => {
    e?.preventDefault(); // Previne o comportamento padrão do form se o evento for passado
    setLoading(true);
    setError(""); // Reseta o erro ao tentar novamente

    try {
      const sessionId = email;
      const response = await login(sessionId);

      setSessionId(sessionId);
      if (response.success) {
        setStage("success");
      } else if (response.qrCode) {
        setQrCode(response.qrCode);
        setStage("qrcode");
      } else if (response.methods) {
        setStage("verificationMethods");
      } else if (response.error) {
        setError(response.error.replace('Email error: ', ''));
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (error) {
      setError(error.response?.data?.message || "An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [email, setSessionId, setStage]); // Adiciona dependências relevantes para evitar a recriação desnecessária

  useEffect(() => {
    // Se um email foi passado pela URL, realiza o login automaticamente
    if (initialEmail) {
      handleSubmit(); // Inicia o login automaticamente
    }
  }, [initialEmail, handleSubmit]); // Inclui handleSubmit nas dependências do useEffect

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              disabled={loading} // Desabilita o input durante o carregamento
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" disabled={loading}>
            {loading ? <div className="spinner"></div> : "Login"} {/* Exibe um spinner durante o carregamento */}
          </button>
        </form>
        {qrCode && (
          <div className="qr-code-container">
            <h3>Scan the QR Code</h3>
            <img src={qrCode} alt="QR Code" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
