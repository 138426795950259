import axios from 'axios';

const API = axios.create({
  baseURL: 'https://pupservice.btks.games/ml',
});

export const login = async (sessionId) => {
  const response = await API.post('/loginAndShowQR', { sessionId });
  return response.data;
};

export const getQRCode = async (sessionId, refresh = false) => {
  const response = await API.post('/getQRCode', { sessionId, refresh });
  return response.data;
};

export const refreshQRCodeButton = async (sessionId) => {
  const response = await API.post('/refreshQRCode', { sessionId });
  return response.data;
};

export const validateCode = async (sessionId, method, code) => {
  const response = await API.post('/validateCode', { sessionId, method, code });
  return response.data;
};

export const selectVerificationMethod = async (sessionId, method) => {
  const response = await API.post('/selectMethod', { sessionId, method });
  return response.data;
};

